import { GoogleTagManager } from '@next/third-parties/google';
import { setContext } from '@sentry/nextjs';
import useCookie from 'app/hooks/use-cookie';
import { cookieCountries } from 'app/modules/Constants';
import Helper from 'app/modules/Helper';
import { DefaultSeo } from 'next-seo';
import type { AppProps } from 'next/app';
import { Montserrat } from 'next/font/google';
import { useSearchParams } from 'next/navigation';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import { Provider, connect } from 'react-redux';
import { agreeCookieNotice, init, setLanguage } from '../actions/baseActions';
import '../app/locales/i18n';
import { useStore } from '../store';
import '../styles/app.scss';
import '../styles/langs.scss';
import '../styles/responsive.scss';

const montserrat = Montserrat({
	subsets: ['latin-ext', 'cyrillic-ext'],
	display: 'swap',
	weight: ['400', '500', '600', '700', '800'],
});

export const initializeConsent = () => {
	if (typeof window !== 'undefined') {
		window.dataLayer = window.dataLayer || [];
		window.gtag =
			window.gtag ||
			function () {
				// eslint-disable-next-line prefer-rest-params
				(window.dataLayer = window.dataLayer || []).push(arguments);
			};

		window.gtag('consent', 'default', {
			functionality_storage: 'denied',
			security_storage: 'granted',
			ad_personalization: 'denied',
			ad_storage: 'denied',
			ad_user_data: 'denied',
			analytics_storage: 'denied',
			personalization_storage: 'denied',
			region: cookieCountries,
		});

		window.gtag('set', 'ads_data_redaction', true);
		window.gtag('set', 'url_passthrough', false);
	}
};

function App({ Component, pageProps }: AppProps) {
	const [hydrated, setHydrated] = useState(false);
	const [renderScript, setRenderScript] = useState(false);
	const [isSiteLoaded, setIsSiteLoaded] = useState(false);
	const [enableA11yScript, setEnableA11yScript] = useState(false);
	const [clarityUserId] = useCookie('_clck');
	const [userIdAvailable, setUserIdAvailable] = useState(false);
	const store = useStore(pageProps.initialReduxState);
	const params = useSearchParams();
	const lng = params?.get('lng') as string;

	if (lng && typeof document !== 'undefined') {
		document.documentElement.lang = lng;

		if (Helper.isRtlLanguage(lng)) {
			document.body.classList.add('rtl');
		}

		const isHebrew = lng === 'he';
		if (isHebrew && !enableA11yScript) {
			setEnableA11yScript(true);
		}
	}

	useEffect(() => {
		let agreeCookieNoticeValue;
		try {
			agreeCookieNoticeValue = localStorage.getItem('cookieNotice');
			if (agreeCookieNoticeValue) {
				store.dispatch(agreeCookieNotice());
			}
		} catch (error) {
			console.error('Failed to read from localStorage, fallback to default value:', error);
		}
		setHydrated(true);
		initializeConsent();
	}, []);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			setRenderScript(!window.location.pathname.includes('/passthru'));
		}
		if (!isSiteLoaded) {
			window.addEventListener('load', () => {
				setIsSiteLoaded(true);
			});
		}
	}, []);

	useEffect(() => {
		if (clarityUserId && !userIdAvailable) {
			setUserIdAvailable(true);
			setContext('Clarity User', {
				id: clarityUserId,
			});
		}
	}, [clarityUserId]);

	if (!hydrated) {
		// Returns null on first render, so the client and server match
		return null;
	}

	return (
		<>
			<DefaultSeo
				openGraph={{
					type: 'website',
					locale: 'en_IE',
					url: process.env.NEXT_PUBLIC_SITE_URL,
					site_name: process.env.NEXT_PUBLIC_SITE_NAME,
				}}
			/>
			{isSiteLoaded && (
				<>
					{enableA11yScript && (
						<Script strategy="afterInteractive" src="https://cdn.userway.org/widget.js" data-account="ckynQOSB8r" />
					)}
					{renderScript && (
						<Script
							strategy="lazyOnload"
							id="Cookiebot"
							src="https://consent.cookiebot.com/uc.js"
							data-cbid="ff633a12-41df-43d8-94fe-11fd95a12a11"
							type="text/javascript"
						/>
					)}
					<GoogleTagManager gtmId="GTM-547DCTJ" />
				</>
			)}
			<main className={montserrat.className}>
				<Component {...pageProps} />
			</main>
		</>
	);
}

const ConnectedMyApp = connect(null, { setLanguage, agreeCookieNotice })(App);

function AppWithProvider({ Component, pageProps, router }: AppProps) {
	const store = useStore(pageProps.initialReduxState);
	// const { growthbook, callback: growthbookCallback } = useGrowthbook();
	// store.dispatch(setGrowthbookCallback(growthbookCallback));

	useEffect(() => {
		store.dispatch(init('hotels'));
	}, []);

	return (
		// <GrowthBookProvider growthbook={growthbook}>
		<Provider store={store}>
			<ConnectedMyApp Component={Component} pageProps={pageProps} router={router} />
		</Provider>
		// </GrowthBookProvider>
	);
}

export default AppWithProvider;
